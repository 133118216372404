#contactPage {
  min-height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 60rem;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contactPageTitle {
  color: rgb(253, 151, 31);
  padding-top: 3rem;
  margin-bottom: 1.5rem;
}
.contactDesc {
  padding: 1rem;
  font-size: medium;
  font-weight: 200;
}
.contactForm {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
  max-width: 60rem;
}
.formInputName,
.formInputEmail,
.formInputMsg {
  font-size: medium;
  width: 100%;
  max-width: 40rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: white;
  border: none;
  border-radius: 0.5rem;
  background: rgba(55, 55, 55, 0.5);
}
.formSubmitBtn {
  background: white;
  border: none;
  border-radius: 0.5rem;
  margin: 2rem;
  padding: 0.75rem 3.5rem;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.formSubmitBtn:hover {
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  scale: 1.03;
}
.links {
  display: flex;
  flex-wrap: wrap;
}
.link {
  object-fit: cover;
  height: 4rem;
  width: 4rem;
  margin: 0 0.75rem;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.link:hover {
  scale: 1.1;
}
.email {
  text-decoration: none;
  color: rgb(249, 38, 114);
  font-weight: 300;
}
.tele {
  text-decoration: none;
  color: rgb(166, 226, 46);
  font-weight: 300;
}
@media screen and (max-width: 768px) {
  #contactPage {
    padding-top: 3rem;
  }
}
@media screen and (max-width: 480px) {
  .link {
    margin: 0 0.3rem;
    height: 3rem;
    width: 3rem;
  }
}
