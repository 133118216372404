#myPortfolio {
  margin: 0 auto;
  min-height: calc(50vh - 4rem);
  width: 100vw;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;
}
.myPortfolioTitle {
  color: rgb(253, 151, 31);
  padding-top: 3rem;
  margin-bottom: 1.5rem;
}
.myPortfolioDesc {
  text-align: justify;
  font-size: 1rem;
  font-weight: 200;
  max-width: 50rem;
  padding: 0 2rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 768px) {
  #myPortfolio {
    min-height: calc(100vh);
  }
}
