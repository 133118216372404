#intro {
  height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.introContent {
  height: 100vh;
  width: 100vw;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.helloWorld {
  font-size: 2.5rem;
  font-weight: 300;
}
.hello {
  color: rgb(249, 38, 114);
}
.world {
  color: rgb(166, 226, 46);
}
.introName {
  color: rgb(253, 151, 31);
}
.introText {
  font-weight: 700;
}
.introParagraf {
  font-size: medium;
  font-weight: 200;
}
.introBtnCont {
  display: flex;
}
.introBtn {
  max-width: 12rem;
  display: flex;
  align-items: flex-start;
  background: white;
  margin: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.introBtn:hover {
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  scale: 1.02;
}
.introBtnImg {
  height: 1rem;
  width: 1rem;
  margin: 0 0.25rem;
  fill: rgb(253, 151, 31);
  color: #000;
  stroke: #000;
}
.introBtnCV {
  height: 1.1rem;
  width: 1.1rem;
  margin: 0 0.25rem;
  fill: #000;
  stroke: rgb(253, 151, 31);
}
.portfolioImg {
  position: absolute;
  top: 20rem;
  right: 10rem;
  z-index: -1;
  object-fit: cover;
  height: 30vh;
  border-radius: 30%;
}

@media screen and (max-width: 768px) {
  .portfolioImg {
    right: 5vw;
    top: 7rem;
  }

  .introText {
    font-size: 3.2rem;
  }
}
@media screen and (max-width: 480px) {
  .helloWorld {
    font-size: 1.75rem;
  }
  .introText {
    font-size: 2.5rem;
  }
  .introContent {
    padding-top: 0;
  }
  .introBtn {
    align-items: center;
  }
  .introBtnImg {
    height: 1.5rem;
    width: 1.5rem;
  }
  .introBtnCV {
    height: 1.5rem;
    width: 1.5rem;
  }
  .introParagraf {
    font-size: 0.9rem;
  }
  .portfolioImg {
    right: 1rem;
    top: 5rem;
    height: 25vh;
  }
}
