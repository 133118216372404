.navbar {
  background: rgba(55, 55, 55, 0.8);
  height: 5rem;
  width: 100vw;
  /* max-width: 75rem; */
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 99;
}
.desktopMenuListItem {
  margin: 1rem;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.desktopMenuListItem:hover {
  color: rgb(253, 151, 31);
}
.active {
  color: rgb(253, 151, 31);
}
.mobMenu {
  display: none;
  object-fit: cover;
  height: 3rem;
  fill: rgb(253, 151, 31);
  stroke: #000;
}
.navMobMenu {
  position: absolute;
  top: 5rem;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  height: fit-content;
  min-width: 15rem;
  background: rgba(55, 55, 55, 0.8);
  border-radius: 1rem 0 1rem 1rem;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mobMenuListItem {
  padding: 0.5rem 3rem;
  margin: 0.25rem;
  background: rgb(33, 33, 33);
}
@media screen and (max-width: 768px) {
  .navbar {
    border-radius: 0;
    padding: 0;
    justify-content: flex-end;
  }
  .mobMenu {
    display: flex;
    justify-content: flex-end;
  }
  .desktopMenu {
    display: none;
  }
  .desktopMenuButton {
    display: none;
  }
}
