.app {
  position: relative;
}
h1 {
  font-size: 3.5rem;
  font-weight: 600;
}
h2 {
  font-size: 2.8rem;
  font-weight: 600;
}
h3 {
  font-size: 1.2rem;
}

.blurred-background {
  background-image: url("./assets/bg.png");
  background-size: cover;
  background-position: center;
  filter: blur(5px);
  -webkit-filter: blur(6px);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
