#aboutMe {
  min-height: calc(100vh - 4rem);
  overflow: hidden;
  width: 100vw;
  max-width: 65rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.aboutMeTitle {
  color: rgb(253, 151, 31);
  padding-top: 3rem;
  margin-bottom: 1.5rem;
}
.aboutMeDesc {
  font-size: 1rem;
  font-weight: 200;
  max-width: 45rem;
  padding: 0 2rem;
  text-align: justify;
}
.pink {
  color: rgb(249, 38, 114);
}
.green {
  color: rgb(166, 226, 46);
}
.orange {
  color: rgb(253, 151, 31);
}
