.footer {
  width: 100vw;
  height: 3rem;
  background: rgba(55, 55, 55, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: small;
}
